import { IJsonPropertyInfo } from "survey-core";
export interface SurveyProperty {
    type: string,
    properties: IJsonPropertyInfo[]
}
export class Labels {

    public static labels: SurveyProperty[] = [
        {
            type: "survey",
            properties: [
                {
                    name: "BODY_REGION",
                    displayName: "Body Region",
                    choices: [
                        { value: "NONE", text: "--" },
                        { value: "AMPUTATION", text: "Amputation" },
                        { value: "ELBOW_PAIN", text: "Arm" },
                        { value: "BACK", text: "Back" },
                        { value: "FOOT_ANKLE", text: "Foot/Ankle" },
                        { value: "HAND_WRIST", text: "Hand/Wrist" },
                        { value: "HIP_GROIN", text: "Hip/Groin" },
                        { value: "KNEE", text: "Knee" },
                        { value: "LEG", text: "Leg" },
                        { value: "OTHER", text: "Other" },
                        { value: "SHOULDER", text: "Shoulder" }
                    ],
                    category: "general"
                },
                {
                    name: "MODULE",
                    displayName: "Module",

                    choices: [
                        { value: "NONE", text: "--" },
                        { value: "SURGERY", text: "Surgery" },
                        { value: "MEDICATION", text: "Medication" },
                        { value: "INJECTION", text: "Injection" },
                        { value: "TREATMENT", text: "Treatment" }
                    ],
                    category: "general"
                }

            ]
        },
        {
            type: "question",
            properties: [
                {
                    name: "BODY_REGION",
                    displayName: "Body Region",
                    choices: [
                        { value: "NONE", text: "--" },
                        { value: "AMPUTATION", text: "Amputation" },
                        { value: "ELBOW_PAIN", text: "Arm" },
                        { value: "BACK", text: "Back" },
                        { value: "FOOT_ANKLE", text: "Foot/Ankle" },
                        { value: "HAND_WRIST", text: "Hand/Wrist" },
                        { value: "HIP_GROIN", text: "Hip/Groin" },
                        { value: "KNEE", text: "Knee" },
                        { value: "LEG", text: "Leg" },
                        { value: "OTHER", text: "Other" },
                        { value: "SHOULDER", text: "Shoulder" }
                    ],
                    type:"multiplevalues",
                    category: "general"
                },
                {
                    name: "HPI_SECTION",
                    displayName: "HPI Section",
                    choices: [
                        { value: "NONE", text: "--" },
                        { value: "CHIEF_COMPLAINT", text: "Chief Complaint" },
                        { value: "RED_FLAGS", text: "Red Flags" },
                        { value: "PAIN", text: "Pain" },
                        { value: "NUMBNESS", text: "Numbness" },
                        { value: "WEAKNESS", text: "Weakness" },
                        { value: "RADIATION", text: "Radiation" },
                        { value: "MOBILITY", text: "Mobility" },
                        { value: "PRIOR_TX", text: "Prior Tx" },
                        { value: "IMAGING", text: "Imaging" },
                        { value: "PMHX", text: "PmHx" },
                        { value: "PSHX", text: "PsHx" },
                        { value: "SCHX", text: "ScHx" },
                        { value: "LMP", text: "LMP" },
                        { value: "MENTAL_HEALTH", text: "Mental Health" }
                    ],
                    category: "general"
                },
                {
                    name: "HPI_BULLET_FORMAT",
                    displayName: "HPI Bullet Format",
                    type: "shorttext",
                    category: "general"
                },
                {
                    name: "HPI_ANSWER_OUTPUT",
                    displayName: "HPI Answer Output",
                    type: "text",
                    category: "general"
                },
                {
                    name: "FOLLOW_UP_HEADING",
                    displayName: "Follow Up Heading",
                    type: "shorttext",
                    category: "general"
                },
                {
                    name: "FOLLOW_UP_QUESTION_LABEL",
                    displayName: "Follow Up Question Label",
                    type: "shorttext",
                    category: "general"
                }

            ]
        }
    ];
}
